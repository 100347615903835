﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

ScrollTrigger.matchMedia({

    "(min-width: 768px)": function () {
        const listItems = document.getElementsByClassName("list-item");

        Array.from(listItems).forEach(listItem => {

            listItem.addEventListener("touchstart", handleListItem);
            listItem.addEventListener("click", handleListItem);
            listItem.addEventListener("mouseenter", handleListItem);
        });

        function handleListItem(evt) {
            const careersOverlays = document.getElementsByClassName("careers-overlay");
            const careersOverlaysContainer = document.getElementsByClassName("careers-overlays-container")[0];
            const overlay = document.getElementById(`${this.dataset.overlay}`);

            Array.from(careersOverlays).forEach(careersOverlay => {
                careersOverlay.style.display = "none";
            });
            careersOverlaysContainer.style.background = `url(${this.dataset.bg})`;
            overlay.style.display = "flex";
        }
    },
    "(max-width: 767px)": function () {
        const slideDuration = 0.3;
        let snapX;

        const slides = document.querySelectorAll(".slide");
        const prevButton = document.querySelector("#prevButton");
        const nextButton = document.querySelector("#nextButton");
        const progressWrap = gsap.utils.wrap(0, 1);

        const numSlides = slides.length;

        gsap.set(slides, {
            xPercent: i => i * 100
        });

        const wrap = gsap.utils.wrap(-100, (numSlides - 1) * 100);

        const animation = gsap.to(slides, {
            xPercent: `+=${numSlides * 100}`,
            duration: 1,
            ease: "none",
            paused: true,
            repeat: -1,
            modifiers: {
                xPercent: wrap
            }
        });

        const proxy = document.createElement("div");
        let slideAnimation = gsap.to({}, {});
        let slideWidth = 0;
        let wrapWidth = 0;
        resize();

        window.addEventListener("resize", resize);

        prevButton.addEventListener("click", function () {
            animateSlides(1);
        });

        nextButton.addEventListener("click", function () {
            animateSlides(-1);
        });

        function animateSlides(direction) {
            slideAnimation.kill();

            const x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);

            slideAnimation = gsap.to(proxy, {
                x: x,
                duration: slideDuration,
                onUpdate: updateProgress
            });
        }

        function updateProgress() {
            animation.progress(progressWrap(gsap.getProperty(proxy, "x") / wrapWidth));
        }

        function resize() {
            const norm = (gsap.getProperty(proxy, "x") / wrapWidth) || 0;

            slideWidth = slides[0].offsetWidth;
            wrapWidth = slideWidth * numSlides;
            snapX = gsap.utils.snap(slideWidth);

            gsap.set(proxy, {
                x: norm * wrapWidth
            });

            animateSlides(0);
            slideAnimation.progress(1);
        }
    }
});